'use client';
import React, { useEffect, useState } from 'react';
import { GoogleTagManager } from '@next/third-parties/google';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';

export const GTM = () => {
	const [id, setId] = useState('');
	const { data: crmData, isStale } = useCrmData();

	const hasValidId = () => id && id !== '';

	useEffect(() => {
		setId(googleTagManager);
	}, []);

	useEffect(() => {
		/**
		 * HACK: GoogleTagManager do not updates itself so keep in mind this
		 * behaviours when cache updates:
		 * - Only gtmId -> Do not updates ID
		 * - gtmId + key -> Add new tag but keeps old script in DOM
		 * - gtmId + key + state -> We can compare and delete previous script
		 *   when needed
		 */
		if (![googleTagManager, ''].includes(id)) {
			document
				.querySelectorAll(
					`script[src="https://www.googletagmanager.com/gtm.js?id=${id}"],
				link[href="https://www.googletagmanager.com/gtm.js?id=${id}"]`,
				)
				.forEach((el) => el.remove());
			setId(googleTagManager);
		}
	}, [isStale]);

	if (!crmData) {
		return null;
	}

	const {
		site: { googleTagManager },
	} = crmData;

	return hasValidId() && <GoogleTagManager key={id} gtmId={id} />;
};
